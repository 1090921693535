/**
 *  Bulma NavBar Burger
 */

const NavBarBurger = {

  init : function() {
    this.bindAction();
  },

  bindAction : function() {

    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach(function ($el) {
        $el.addEventListener('click', function () {
          
          // Get the target from the "data-target" attribute
          let target = $el.dataset.target;
          let $target = document.getElementById(target);
          
          // Toggle the class on both the "navbar-burger" and the "navbar-menu"
          $el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
          
        });
      });
    }
  }

}




const MegaTimer = (function() {

  // console.log('MegaTimer running');

  // get top-level items from a dropdown menu
  var menuItems = document.querySelectorAll('.navbar .navbar-item');

  // set actions for mouse over and mouse leave
  var hi = new window.HoverIntent(menuItems, {
    // required parameters
    onEnter: function(targetItem) {
      targetItem.classList.add('active');
    },
    onExit: function(targetItem) {
      targetItem.classList.remove('active');
    },

    // default options
    exitDelay: 400,
    interval: 100,
    sensitivity: 7,
  });

});

export { NavBarBurger, MegaTimer }
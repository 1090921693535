/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

// import 'boxicons';

/**
 *  Dropzone
 */
import Dropzone from "dropzone"
// Make sure Dropzone doesn't try to attach itself to the
// element automatically.
// This behaviour will change in future versions.
Dropzone.autoDiscover = false;
window.Dropzone = Dropzone;

import UniversalDropzone from "./modules/dropzone-universal";

import { NavBarBurger } from "./modules/megamenu";


// const { littlefoot } = require('littlefoot');


import Glightbox from 'glightbox';


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


document.addEventListener('DOMContentLoaded', function() {

    let token = document.head.querySelector('meta[name="csrf-token"]');

    // const app = new Vue({
    //     el: '#app',
    // });


    NavBarBurger.init();

    const glightbox = Glightbox();

    UniversalDropzone.run(token);





    // const lf = littlefoot({
    //   activateOnHover: true,
    //   hoverDelay: 250,
    // });

});





/**
 * universal dropzone 
 */
export default {

  run : function(token) {

    // console.log(token.content)

    let dropzones = []
    let dropzoneElements = document.querySelectorAll('.universal-dropzone') || []
    
    dropzoneElements.forEach((elm, i) => {

    // for(var i=0; i<dropzoneElements.length; i++) {

      let url = elm.dataset.url
      // let url = dropzoneElements[i].dataset.url

        

      dropzones.push(new Dropzone(elm, {
          url: url,
          paramName: "uni_upload",
          maxFilesize: 4096,
          headers: { 'X-CSRF-TOKEN': token.content }
      }))

      dropzones[i].on("sending", function(file, xhr, formData) {
        formData.append("_method", "PUT")
      })

      dropzones[i].on("success", function(file, response) {
        // let block = elm.closest('.universal-dropzone-block')
        // let target = block.querySelector('.universal-dropzone-callback-target')
        // console.log(target)
        // target.src = response.filepath
        // console.log(response.filepath)
      })

      dropzones[i].on("error", function(file, error) {
        console.log(error)
      })

    })

  }

}